var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex": "1",
      "flex-dir": "column",
      "overflow": "auto",
      "pb": "30px"
    }
  }, [_vm.isEmpty ? _c('meal-plan-empty') : _c('meal-plan-non-empty', {
    attrs: {
      "menus": _vm.rencanaMakan
    }
  }), _vm.isProfileGiziEmpty ? _c('Portal', {
    attrs: {
      "to": "main"
    }
  }, [_c('c-alert-dialog', {
    attrs: {
      "is-open": ""
    }
  }, [_c('c-alert-dialog-overlay'), _c('c-alert-dialog-content', {
    attrs: {
      "pos": "fixed",
      "top": "50%",
      "left": "50%",
      "transform": "translate(-50%, -50%)"
    }
  }, [_c('c-alert-dialog-body', {
    attrs: {
      "py": "20px"
    }
  }, [_vm._v(" Anda belum mengisi profile gizi. ")]), _c('c-alert-dialog-footer', [_c('c-button', {
    attrs: {
      "color": "primary.400",
      "as": "router-link",
      "to": {
        name: 'nutri.profile-gizi',
        params: _vm.$route.params
      }
    }
  }, [_vm._v(" Isi profile gizi ")])], 1)], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }