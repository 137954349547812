var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-dir": "column"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-dir": "column",
      "mt": "15px",
      "px": "15px"
    }
  }, [_c('c-flex', {
    attrs: {
      "my": "10px",
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "flex": "1",
      "font-weight": "bold",
      "font-size": "18px",
      "color": "#111111"
    }
  }, [_vm._v(" Rencana makan ")]), !_vm.isNutri ? _c('c-button', {
    attrs: {
      "variant": "outline",
      "variant-color": "primary",
      "rounded": "lg",
      "as": "router-link",
      "to": {
        query: {
          intro: 1
        }
      }
    }
  }, [_c('c-text', [_vm._v("Info")]), _c('c-image', {
    attrs: {
      "ml": "5px",
      "src": require('@/assets/ic-help-filled.svg'),
      "alt": "icon help"
    }
  })], 1) : _vm._e(), _vm.isNutri ? _c('c-button', {
    attrs: {
      "variant-color": "primary",
      "border-radius": "50px",
      "as": "router-link",
      "to": {
        name: 'nutri.meal-plan.edit'
      }
    }
  }, [_c('c-image', {
    attrs: {
      "mr": "10px",
      "src": require('@/assets/icon-pencil.svg'),
      "alt": "icon"
    }
  }), _vm._v(" Edit ")], 1) : _vm._e()], 1), _c('c-flex', {
    attrs: {
      "p": "8px",
      "mt": "8px",
      "background": "#F2F9F9",
      "border-radius": "8px"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-info-circle-grey.svg'),
      "alt": "icon info",
      "h": "18px",
      "w": "18px"
    }
  }), _c('c-text', {
    attrs: {
      "ml": "8px",
      "font-size": "12px",
      "color": "#555555"
    }
  }, [_vm._v(" Daftar golongan makanan di bawah ini opsional. ")])], 1)], 1), _vm._l(_vm.menus_, function (menu, index) {
    return _c('card-diet', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      key: menu.id,
      attrs: {
        "menu": menu,
        "mt": [index == 0 ? '40px' : 0]
      }
    });
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }