<template>
  <c-flex
    flex="1"
    flex-dir="column"
    overflow="auto"
    pb="30px"
  >
    <meal-plan-empty v-if="isEmpty" />
    <meal-plan-non-empty
      v-else
      :menus="rencanaMakan"
    />

    <Portal
      v-if="isProfileGiziEmpty"
      to="main"
    >
      <c-alert-dialog is-open>
        <c-alert-dialog-overlay />
        <c-alert-dialog-content
          pos="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          <c-alert-dialog-body py="20px">
            Anda belum mengisi profile gizi.
          </c-alert-dialog-body>
          <c-alert-dialog-footer>
            <c-button
              color="primary.400"
              as="router-link"
              :to="{ name: 'nutri.profile-gizi', params: $route.params }"
            >
              Isi profile gizi
            </c-button>
          </c-alert-dialog-footer>
        </c-alert-dialog-content>
      </c-alert-dialog>
    </Portal>
  </c-flex>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import MealPlanEmpty from './meal-plan-empty.vue'
import MealPlanNonEmpty from './meal-plan-non-empty.vue'

export default {
  name: 'MealPlanPage',
  components: { MealPlanEmpty, MealPlanNonEmpty },
  data() {
    return {
      isProfileGiziEmpty: false,
    }
  },
  computed: {
    ...mapState({
      rencanaMakan: (s) => [
        ...s.mealPlan.rencanaMakan,
        ...s.clients.rencanaMakan,
      ],
      latestProgram: (s) => s.mealPlan.latestProgram,
      // profileGizi: (s) => s.mealPlan.profileGizi,
    }),
    profileGizi() {
      return this.$store.state.mealPlan.profileGizi
    },
    profileGizi_() {
      return JSON.parse(JSON.stringify(this.profileGizi))
    },
    isEmpty() {
      return this.rencanaMakan.length <= 0
    },
    clientId() {
      return this.$route.params.clientId
    },
    isNutri() {
      return (
        this.$route.matched
          .map((it) => it.meta)
          .reduce((res, it) => ({ ...res, ...it }), {}).isNutritionist ?? false
      )
    },
  },
  watch: {
    profileGizi: {
      immediate: false,
      handler(profileGizi) {
        let val = JSON.parse(JSON.stringify(profileGizi ?? {}))

        if (this.isClient) return
        let values = Object.values(val)
        this.isProfileGiziEmpty = values.length > 0
          ? values.every((it) => it == null)
          : true
      },
    },
  },
  async mounted() {
    let role = this.authRole

    if (this.programId != null) {
      await this.$store.dispatch('clients/getRencanaMakan', this.programId)
    } else if (role === 'nutritionist' && this.clientId != null) {
      let programId = await this.$store
        .dispatch('mealPlan/getLatestProgramForNutri', this.clientId)
        .then((it) => it.id)
      await this.listDetail({
        clientId: this.clientId,
        programId,
      })
      await this.$store.dispatch('mealPlan/getProfileGizi', this.clientId)
    } else {
      await this.$store.dispatch('mealPlan/getLatestProgramForClient')
    }
  },
  methods: {
    ...mapActions({
      listDetail: 'mealPlan/list',
    }),
  },
}
</script>
